import { ApiService } from './api.service';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from './utility.service';
import { Device } from '@capacitor/device';
import { UserPreference } from './userPreference.service';
@Injectable({
  providedIn: 'root',
})
export class Applogin {
  one_signal_token: string = '';
  loggedIn: boolean = false;
  user_profile: any = [];
  constructor(
    private api: ApiService,
    private utility: UtilityService,
    private router: Router,
    private alertCtrl: AlertController,
    private userpreference: UserPreference
  ) {}

  async app_login() {
    const info = await Device.getInfo();
    let device_detail = {
      manufacturer: info['manufacturer'],
      model: info['model'],
      platform: info['platform'],
      version: info['osVersion'],
      one_signal_token: this.one_signal_token,
      uuid: info['uuid'] || '',
      serial: info['serial'] || '',
    };
    let loader = this.utility.loader_create('Please Wait');
    this.api.postData('login', device_detail).subscribe(async (res) => {
      (await loader).dismiss();
      if (res['status'] == true) {
        this.router.navigate([''], { replaceUrl: true });
      }
    });
  }

  async logoutCheck() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Log Out',
      message: 'Are you sure you want to log out ?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Yes',
          handler: async () => {
            this.utility.logout();
            this.router.navigate(['/tab-page/home'], { replaceUrl: true });
          },
        },
      ],
    });
    await alert.present();
  }

  async checkUsername() {
    const userLogin = this.userpreference.getUser_detail();
    if (userLogin) {
      userLogin.then((res: any) => {
        if (res) {
          return this.utility.find_user_data(res);
        }
      });
    }
  }

  checkLoginStatus() {
    const loggedIn = this.userpreference.isLoggedIn.value;
    return this.updateLoggedInStatus(loggedIn);
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.checkUsername();
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });
    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }
}
