import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserPreference } from './service/userPreference.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private userpreference: UserPreference, private router: Router) {}
  async getAuth() {
    return await this.userpreference.getAuthToken();
  }

  canActivate(): boolean {

    if (!this.getAuth()) {
      this.router.navigate(['/login-id-pss']);
      return false;
    }
    return true;
  }
}
