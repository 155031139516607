import { UserPreference } from './userPreference.service';
import { cartParam } from './Modals';
import { Injectable } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Preferences } from '@capacitor/preferences';
import { Clipboard } from '@capacitor/clipboard';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public logIn = new BehaviorSubject<number>(0);
  public one_signal_token = new BehaviorSubject<any>(null);
  public refresh_data = new BehaviorSubject<any>(false);
  private cartCount = new BehaviorSubject<number>(0);
  private isXLarge = new BehaviorSubject<boolean>(false);
  isXLarge$ = this.isXLarge.asObservable();
  cartCount$ = this.cartCount.asObservable();
  cartParam: cartParam;
  userLogin: any = [];
  accessToken: any = '';
  constructor(
    private toastCtrl: ToastController,
    private apiService: ApiService,
    private loadingCtrl: LoadingController,
    private userpreference: UserPreference,
    private platform: Platform
  ) {
    this.init();
  }

  async init() {
    await this.getUserToken();
  }

  async login(data: any): Promise<any> {
    this.logIn.next(data);
    return window.dispatchEvent(new CustomEvent('user:login'));
  }

  setXLarge(value: boolean) {
    this.isXLarge.next(value);
  }

  setCartCount(value: number) {
    this.cartCount.next(value);
  }


  async set_refresh(): Promise<any> {
    this.refresh_data.next(true);
  }

  async one_signal(data: any): Promise<any> {
    this.one_signal_token.next(data);
  }

  async getUserToken() {
    let res = await this.userpreference.getToken();
    if (res) {
      this.getCartCount();
    } else {
      this.apiService.getUserToken().subscribe((res: any) => {
        if (res.status == true) {
          let userToken = res.data;
          this.userpreference.setToken(userToken).then(async (res: any) => {
            await this.getUserToken();
          });
        }
      });
    }
  }

  async getCartCount() {
    let user_token = await this.userpreference.getToken();
    this.cartParam = { user_token: user_token };
    this.apiService.getCartCount(this.cartParam).subscribe((res: any) => {
      this.setCartCount(res.getCartCount)
    });
  }

  async writeToClipboard(text: any) {
    await Clipboard.write({
      string: text,
    });
  }

  logout() {
    this.apiService.logout().subscribe((res: any) => {
      if (res.status == true) {
        this.showToast(res.message, 'success');
        this.userpreference.clear();
      } else {
        return;
      }
    });
  }

  async showToast(message: string, color: string = 'info') {
    // let position = this.platform.is('hybrid') ? 'bottom' : 'top';

    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: this.platform.is('hybrid') ? 'bottom' : 'top',
      color: color,
    });
    toast.present();
  }

  public async setStorageItem(key: string, value: any) {
    let param = {
      key: key,
      value: value,
    };
    return await Preferences.set(param);
  }

  public async removeStorageItem(key: any) {
    return Preferences.remove(key);
  }

  public async cleartorageItem() {
    Preferences.clear();
  }

  public async getStorageItem(key: any) {
    return await Preferences.get({ key: key });
  }

  async loadingPresent() {
    return await this.loadingCtrl
      .create({
        cssClass: 'dataLoad',
        backdropDismiss: false,
        showBackdrop: false,
      })
      .then((a) => {
        a.present().then(() => {
          a.dismiss();
        });
      });
  }

  DateTimeTransform(time: any): any {
    let hour = time.split(':')[0];
    let min = time.split(':')[1];
    let part = hour > 12 ? 'PM' : 'AM';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
  }

  async loader_create(message: string = 'Loading....') {
    let loader = this.loadingCtrl.create({
      message: message,
      duration: 5000,
    });
    (await loader).present();
    return loader;
  }

  async loadingDismiss() {
    return await this.loadingCtrl.dismiss();
  }

  find_user_data(profile: any) {
    return JSON.parse(atob(profile));
  }

  changeDateFormate(date: string | number | Date): string {
    let dt = new Date(date);
    let year = dt.getFullYear();
    let month = (dt.getMonth() + 1).toString().padStart(2, '0');
    let day = dt.getDate().toString().padStart(2, '0');
    let tda = year + '-' + month + '-' + day;
    return tda;
  }

  showDate(endDate: any) {
    if (endDate) {
      let dt = new Date();
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, '0');
      let day = (dt.getDate() - 1).toString().padStart(2, '0');
      let yester = year + '-' + month + '-' + day;
      let e_date = this.changeDateFormate(endDate);
      let today = this.changeDateFormate(dt);
      let yeasterDay = this.changeDateFormate(yester);
      return e_date == today
        ? 'Today'
        : yeasterDay == e_date
        ? 'Yesterday'
        : e_date;
    } else {
      return '----';
    }
  }

  async openlink(type: string, msg = '') {
    const mob = await this.userpreference.getWhatsapp_number();
    // console.log(mob);
    let urlname = '#';
    if (type && mob) {
      const encoded = encodeURI(msg);
      if (type.toLocaleLowerCase() == 'watsapp') {
        urlname = 'https://wa.me/+91' + mob + '?text=' + encoded;
      }
      if (urlname !== '#') {
        window.location.href = urlname;
      }
    } else {
    }
  }
}
