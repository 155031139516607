import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UserPreference } from './userPreference.service';
import { base_url } from './config';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  public authorized_token: string = '';
  baseUrl = base_url + 'api/';
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private http: HttpClient,
    private userpreference: UserPreference
  ) {
    this.getAuthToken();
  }
  urlfilter = ['login'];

  async getAuthToken() {
    const token = await this.userpreference.getAuthToken();
    if (token) this.authorized_token = token;
  }
  getConnect(requestMethod: string, end_point: any, options?: any) {
    try {
      let http: any;
      let header: any;
      if (this.urlfilter.indexOf(end_point) != -1) {
        header = {
          headers: new HttpHeaders().set('Content-Type', 'application/json').set('Content-Type', 'application/json'),
        };
      } else {
        header = {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this.authorized_token}`),
        };
      }
      if (requestMethod.toUpperCase() == 'GET') {
        let url = this.checkUrlElement(end_point)
          ? end_point
          : this.baseUrl + end_point;
        header['params'] = options;
        http = this.http.get(url, header);
      } else {
        http = this.http.post(this.baseUrl + end_point, options, header);
      }
      return http.pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(this.handleError)
      );
    } catch (e) {
    }
  }

  // async cancel_all_connection(): Promise<void> {
  //   this.ngUnsubscribe.next();
  //   this.ngUnsubscribe.complete();
  // }
  checkUrlElement(str: any): boolean {
    var a = document.createElement('a');
    a.href = str;
    if (a.host && a.host != window.location.host) {
      return true;
    } else {
      return false;
    }
  }

  public handleError(error: HttpErrorResponse) {
    // this.cancel_all_connection();
    // this.loadingController.getTop().then(async(res:any)=>{
    //   if(res !== undefined) {
    //     await res.dismiss();
    //   }
    // });
    // setTimeout(() => this.checkAndCloseLoader(), 1000);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.log('An error occurred:', error.error);
    } else if (error.status === 401) {
      this.userpreference.clear();
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // console.log(
      //   `Backend returned code ${error.status}, body was: `,
      //   error.error
      // );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
