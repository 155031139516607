import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ApiService } from './service/api.service';
import { Component } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { UtilityService } from './service/utility.service';
import { Applogin } from './service/applogin.service';
import { OnsignalNotificationSer } from './service/onsignalnotification.service';
import { BackButtonEvents } from './service/backbuttonevents.service';
import { UserPreference } from './service/userPreference.service';
import { Device } from '@capacitor/device';
import { ConnectService } from './service/connectservice.service';
import { CameraService } from './service/cameraService';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appV: string = '';
  image_url = '';
  one_signal_token: string = '';
  user_profile: any = [];
  loggedIn: boolean = false;
  XLarge: boolean = false;
  cartCount: number = 0;
  image: string = '';
  isActive: string = 'home';
  logo:'assets/imgs/BrandLibrary-logo.png';

  public appPages = [
    // {title:'My Wallet',url:'My Wallet',icon:'wallet information'},
    {
      title: 'About-us',
      url: 'page/about-us',
      icon: 'assets/icon/aboutUs.png',
    },
    {
      title: 'Terms and Condition',
      url: 'page/terms-and-condition',
      icon: 'assets/icon/t&c.png',
    },
    {
      title: 'Privacy Policy',
      url: '../page/privacy-policy',
      icon: 'assets/icon/policy.png',
    },
    {
      title: 'Contact Us',
      url: 'contact-us',
      icon: 'assets/icon/contact-us-icon.jpg',
    },
    {
      title: 'Packeges',
      url: '/packeges',
      icon: 'assets/icon/membership.svg',
    },
    { title: 'Payment', url: 'page/payment', icon: 'assets/icon/bank.png' },
    {
      title: 'Help Us & Support',
      url: '/help-support',
      icon: 'assets/icon/support.png',
    },
    {
      title: 'Get Consultation ',
      url: '/enquiry-form',
      icon: 'assets/icon/consultation.png',
    },
  ];
  constructor(
    private platform: Platform,
    public utility: UtilityService,
    public preferences: UserPreference,
    public apiService: ApiService,
    private router: Router,
    private onsignalNotification: OnsignalNotificationSer,
    private backButtonEvents: BackButtonEvents,
    private connectservice: ConnectService,
    private appLogin: Applogin,
    private breakpointObserver: BreakpointObserver,
    private menuController: MenuController,
    public location: Location
  ) {
    this.initializeApp();
    // this.appLogin.app_login();
    this.listenForLoginEvents();
    this.checkLoginStatus();
  }

  async initializeApp() {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        if (result.matches) {
          this.utility.setXLarge(false);
        } else {
          this.utility.setXLarge(true);
        }
      });

    this.utility.isXLarge$.subscribe((res) => {
      this.XLarge = res;
    });
    this.utility.cartCount$.subscribe((res) => {
      this.cartCount = res;
    });
    this.getCurrentUrl();
    this.platform.ready().then(async () => {
      if (this.platform.is('hybrid')) {
        document.body.setAttribute('data-theme', 'light');
        document.body.classList.toggle('dark', false);
        StatusBar.setOverlaysWebView({ overlay: false });
        // StatusBar.setOverlaysWebView({ overlay: true });
        this.setStatusBarStyleDark();
        this.onsignalNotification.setUpOneSignal();
        const info = await Device.getInfo();
        this.utility
          .getStorageItem('one_signal_token')
          .then(async (res: any) => {
            let param = {
              tkn: res.value,
              devinfo: info,
            };
            this.apiService.saveFcmToken(param).subscribe((data: any) => {});
          });

        this.backButtonEvents.backButtonEvent();
      }
    });
    this.image_url = this.apiService.image_url;
  }
  getCurrentUrl() {
    const currentUrl = this.location.path();
  }

  setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Light });
    await StatusBar.setBackgroundColor({ color: '#ffffff' });
  };
  async logoutCheck() {
    // this.menu.close();
    this.utility.logout();
  }

  get_profile() {
    // this.menu.close();
    this.router.navigate(['/tab-page/account']);
  }

  logIn() {
    // this.menu.close();
    this.router.navigateByUrl('login-id-pss');
  }

  ShareApp = () => {
    return false;
  };
  menuOpen() {
    if (this.XLarge) {
      this.menuController.enable(true);
      this.menuController.open();
      const menu = this.menuController.getMenus();
    }
  }
  gotoWallet() {
    let url = 'login-id-pss';
    if (this.loggedIn) {
      url = 'wallet';
    }
    this.router.navigateByUrl(url);
  }

  goTo(url: any, tab: string) {
    this.router.navigateByUrl(url);
    if (tab) {
      this.isActive = tab;
    }
  }
  async checkUsername() {
    const userLogin = await this.preferences.getUser_detail();
    if (userLogin && userLogin.value) {
      this.loggedIn = this.preferences.isLoggedIn.value;
      this.user_profile = await this.utility.find_user_data(userLogin.value);
    }
  }

  async checkLoginStatus() {
    let loggedIn = false;
    const checktoken = await this.preferences.getAuthToken();
    if (checktoken) {
      loggedIn = true;
    }
    return this.updateLoggedInStatus(loggedIn);
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.preferences.isLoggedIn.next(loggedIn);
      this.checkUsername();
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.connectservice.authorized_token = '';
      this.utility.getUserToken();
      this.updateLoggedInStatus(false);
    });
  }

  async updateImage() {
    let cameraservice = new CameraService();
    this.image = await cameraservice.upload();
    await this.uploadImage();
  }

  async uploadImage() {
    let loader = await this.utility.loader_create('Please Wait');
    let imageparam = {
      imagefile: this.image,
    };

    this.apiService
      .changeProfileImage(imageparam)
      .subscribe(async (res: any) => {
        if (res['status'] == true) {
          this.preferences.setUser_detail(res['data']['profile_token']); // set user profile details
          const user_profile = await this.utility.find_user_data(
            res['data']['profile_token']
          );
          this.preferences.setProfilePicture(user_profile?.picture);
          this.user_profile = await this.preferences.getProfilePicture();

          // this.checkUsername();
        }
        await loader.dismiss();
      });
  }
}
