import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, PopoverController, ModalController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class BackButtonEvents {
  constructor(
    private platform: Platform,
    private router: Router,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private location: Location,
  ) {
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, async (processNextHandler) => {
      let url = this.router.url;
      if (url == '/tab-page/home' || url == '/user-signup-final' || url == '/user-signup' || url == '/user-login') {
        navigator['app'].exitApp();
      }
      else {
        try {
          const element = await this.popoverCtrl.getTop();
          if (element) {
            element.dismiss();
            return;
          }
        } catch (error) {
          console.log("popoverCtrl", error);
        }
        try {
          const element2 = await this.modalCtrl.getTop();
          if (element2) {
            element2.dismiss();
            return;
          }
        } catch (error) {
          console.log("modalCtrl", error);
        }
        this.location.back();
      }
      processNextHandler();
    })
  }
}
