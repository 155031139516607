import { Component, OnInit } from '@angular/core';
import { UtilityService } from './../../service/utility.service';
import { ApiService } from './../../service/api.service';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-edit-mobile-email',
  templateUrl: './edit-mobile-email.component.html',
  styleUrls: ['./edit-mobile-email.component.scss'],
})
export class EditMobileEmailComponent implements OnInit {
  otp_sent_status: boolean = false;
  user_mobile: any = "";
  user_email: any = "";
  fieled_type: any = "";
  reg_user_token: any = "";
  mobile_otp: any = "";
  otp_resend_loop: any;
  intervalTime: number = 60;
  user_profile: any = [];
  constructor(
    private utility: UtilityService,
    private api: ApiService,
    private popoverCtr: PopoverController,
    private navpara: NavParams
  ) {
    this.fieled_type = this.navpara.get('fieled_type');
    this.user_profile = this.navpara.get('user_profile');
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.otp_sent_status = false;
    this.user_mobile = "";
  }

  user_register() {
    let user_mobile = this.user_mobile;
    let user_email = this.user_email;
    if (user_mobile.length != 10 && this.fieled_type == 'user_mobile') {
      this.utility.showToast("Enter mobile number", 'danger')
    }
    else if (user_email == '' && this.fieled_type == 'user_email') {
      this.utility.showToast("Enter email", 'danger')
    }
    else {
      let update_text = this.fieled_type == 'user_mobile' ? user_mobile : user_email;
      let loader = this.utility.loader_create('Please Wait')
      this.api.get_data("send-mobile-email-otp", { 'user_id': this.user_profile['user_id'], 'update_text': update_text, 'plateform': 'APP', 'fieled_type': this.fieled_type })
        .subscribe(async data => {
          (await loader).dismiss();
          if (data['status'] == 'success') {
            this.utility.showToast(data['message'], 'success');
            this.otp_sent_status = true;
            this.reg_user_token = data['user_token'];
            this.intervalTime = 60;
            this.otp_resend_loop = setInterval(() => {
              this.intervalTime--;
              if (this.intervalTime < 0) {
                clearInterval(this.otp_resend_loop);
              }
            }, 1000);
          } else {
            this.utility.showToast(data['message'], 'danger');
          }
        })
    }
  }

  update_mobile_email() {
    let user_mobile = this.user_mobile;
    let user_email = this.user_email;
    if (user_mobile.length != 10 && this.fieled_type == 'user_mobile') {
      this.utility.showToast("Enter mobile number", 'danger')
    }
    else if (user_email == '' && this.fieled_type == 'user_email') {
      this.utility.showToast("Enter email", 'danger')
    }
    else if (this.mobile_otp == '') {
      this.utility.showToast("Enter otp", 'danger')
    }
    else {
      let update_text = this.fieled_type == 'user_mobile' ? user_mobile : user_email;
      let loader = this.utility.loader_create('Please Wait')
      this.api.get_data("update-mobile-email", {
        'update_text': update_text, 'fieled_type': this.fieled_type, user_id: this.user_profile['user_id'],
        'plateform': 'APP', 'mobile_otp': this.mobile_otp, 'reg_user_token': this.reg_user_token
      })
        .subscribe(async data => {
          (await loader).dismiss();
          if (data['status'] == 'success') {
            this.utility.showToast(data['message'], 'success');
            clearInterval(this.otp_resend_loop);
            if (this.fieled_type == 'user_mobile') {
              this.user_profile['user_mobile'] = user_mobile;
            }
            else if (this.fieled_type == 'user_email') {
              this.user_profile['user_email'] = user_email;
            }
            this.utility.setStorageItem('user_profile', btoa(JSON.stringify(this.user_profile)));
            this.utility.set_refresh();
            this.popoverCtr.dismiss();
          } else {
            this.utility.showToast(data['message'], 'danger');
          }
        })
    }
  }
  close_modal() {
    this.popoverCtr.dismiss();
  }
}
