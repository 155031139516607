import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/service/api.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.scss'],
})
export class UserWalletComponent implements OnInit {

  wallet_balance: any = 0;
  winning_balance: any = 0;
  cash_balance: any = 0;
  added_balance: any = 0;
  user_profile: any = [];

  constructor(private utility: UtilityService,
    private router: Router,
    private navpara: NavParams,
    private popoverCtr: PopoverController,
    private api: ApiService) {
    let user_profile = navpara.get('user_profile');
    if (user_profile) {
      this.user_profile = user_profile;
      this.get_user_data();
    }
    else {
      this.close_modal();
    }
  }

  ngOnInit() { }
  get_user_data() {
    let loader = this.utility.loader_create('Please Wait')
    this.api.get_data("get-user-wallet", { 'user_id': this.user_profile['user_id'] })
      .subscribe(async data => {
        (await loader).dismiss();
        if (data['status'] == 'success') {
          this.wallet_balance = data['wallet_balance'];
          this.winning_balance = data['winning_balance'];
          this.cash_balance = data['cash_balance'];
          this.added_balance = data['added_balance'];
        } else {
          this.utility.showToast(data['message'], 'danger');
        }
      })
  }

  close_modal() {
    this.popoverCtr.dismiss();
  }

  async add_wallet_balance() {
    try {
      const element = await this.popoverCtr.getTop();
      if (element) {
        element.dismiss();
      }
    } catch (error) {
      console.log("modalCtrl", error);
    }

    let navigationExtras: NavigationExtras = {
      state: {
        user_profile: this.user_profile,
        wallet_balance: this.wallet_balance
      }
    };
    this.router.navigate(['wallet'], navigationExtras);
  }

}
