import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPreference {
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public userToken = new BehaviorSubject<any>('');
  userLogin: any = [];
  async setToken(user_token: string) {
    return await this.setName('user_token', user_token);
  }

  async getToken() {
    let getToken = (await this.getName('user_token')).value;
    return getToken;
  }

  async setAuthToken(access_token: string) {
    return await this.setName('access_token', access_token).then(() => {
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  async getAuthToken() {
    return (await this.getName('access_token')).value;
  }

  async setPrime(prime: number) {
    return await this.setName('prime', prime);
  }

  async getPrime() {
    return (await this.getName('prime')).value;
  }

  async setWhatsapp_number(whatsapp_number: number) {
    return await this.setName('whatsapp_number', whatsapp_number);
  }

  async getWhatsapp_number() {
    return (await this.getName('whatsapp_number')).value;
  }

  async setUser_detail(user_detail: any) {
    return await this.setName('user_detail', user_detail).then(() => {
      this.userLogin = user_detail;
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  async getUser_detail() {
    return this.getName('user_detail').then((res: any) => {
      return (this.userLogin = res);
    });
  }

  async setProfilePicture(profilePicture: string) {
    return await this.setName('profilePicture', profilePicture).then(() => {
      return window.dispatchEvent(new CustomEvent('user:profilePicture'));
    });
  }

  async getProfilePicture() {
    return (await this.getName('profilePicture')).value;
  }

  async setName(key: string, value: any) {
    return await Preferences.set({
      key: key,
      value: value,
    });
  }

  async getName(key: string) {
    return await Preferences.get({ key: key });
  }

  async removeName() {
    await Preferences.remove({ key: 'name' });
  }

  async clear() {
   const clear = await Preferences.clear();
    this.isLoggedIn.next(false);
    await this.getToken();
    return window.dispatchEvent(new CustomEvent('user:logout'));
  }
}
