import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
export class CameraService {

  async upload() {

    const actionSheet = await ActionSheet.showActions({
      options: [
        {
          title: 'Take Photo',
        },
        {
          title: 'Choose From Gallery',
        },
        {
          title: 'Cancel',
        }
      ]
    });

    if (actionSheet.index == 0) {
      const data = await this.addImage(CameraSource.Camera);
      return data;
    } else if (actionSheet.index == 1) {
      const data = await this.addImage(CameraSource.Photos);
      return data;
    }

  }

  async addImage(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      source
    });
    return `data:image/${image.format};base64,` + image.base64String;
  }

}
