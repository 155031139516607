import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root'
})
export class OnsignalNotificationSer {
  one_signal_token: string = "";
  constructor(
    private utility: UtilityService,
  ) { }
  async setUpOneSignal() {
    OneSignal.setAppId('5439a7ae-a795-49c2-8584-dcaef3b5fb2b');
    let utility = this.utility;
    let one_signal_token = this.one_signal_token;
    OneSignal.addSubscriptionObserver(async function (state) {
      if (!state.from.isSubscribed && state.to.isSubscribed && state.to.userId) {
        utility.setStorageItem('one_signal_token', state.to.userId);
        utility.one_signal(state.to.userId);
        one_signal_token = state.to.userId;
      }
    })
    OneSignal.addPermissionObserver(function (state) {
      console.log("PermissionObserver:", state)
    })
    OneSignal.setNotificationOpenedHandler(function (data) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(data));
    });
    OneSignal.getDeviceState(function (state) {
      console.log('state:' + state);
    })
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });
  }
}
