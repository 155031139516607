import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tab-page',
    pathMatch: 'full',
  },
  // {
  //   path: 'user-login',
  //   loadChildren: () => import('./user-login/user-login.module').then(m => m.UserLoginPageModule)
  // },
  // {
  //   path: 'user-signup',
  //   loadChildren: () => import('./user-signup/user-signup.module').then(m => m.UserSignupPageModule)
  // },
  // {
  //   path: 'user-signup-final',
  //   loadChildren: () => import('./user-signup-final/user-signup-final.module').then(m => m.UserSignupFinalPageModule)
  // },

  {
    path: 'notification',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('./pages/wallet/wallet.module').then((m) => m.WalletPageModule),
  },
  {
    path: 'wallet-history',
    loadChildren: () =>
      import('./pages/wallet-history/wallet-history.module').then(
        (m) => m.WalletHistoryPageModule
      ),
  },

  {
    path: 'tab-page',
    loadChildren: () =>
      import('./tab-page/tab-page.module').then((m) => m.TabPagePageModule),
  },

  {
    path: 'profile-edit',
    loadChildren: () =>
      import('./pages/profile-edit/profile-edit.module').then(
        (m) => m.ProfileEditPageModule
      ),
      canActivate:[AuthGuard]
  },
  {
    path: 'profile-bank',
    loadChildren: () =>
      import('./pages/profile-bank/profile-bank.module').then(
        (m) => m.ProfileBankPageModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },

  {
    path: 'my-wallet',
    loadChildren: () =>
      import('./pages/my-wallet/my-wallet.module').then(
        (m) => m.MyWalletPageModule
      ),
  },

  {
    path: 'content-detail',
    loadChildren: () =>
      import('./pages/content-detail/content-detail.module').then(
        (m) => m.ContentDetailPageModule
      ),
  },

  {
    path: 'sub-category',
    loadChildren: () =>
      import('./sub-category/sub-category.module').then(
        (m) => m.SubCategoryPageModule
      ),
  },
  {
    path: 'product-detail',
    loadChildren: () =>
      import('./product-detail/product-detail.module').then(
        (m) => m.ProductDetailPageModule
      ),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./cart/cart.module').then((m) => m.CartPageModule),
  },
  {
    path: 'buy-now',
    loadChildren: () =>
      import('./buy-now/buy-now.module').then((m) => m.BuyNowPageModule),
  },
  {
    path: 'login-id-pss',
    loadChildren: () =>
      import('./login-id-pss/login-id-pss.module').then(
        (m) => m.LoginIdPssPageModule
      ),
  },

  {
    path: 'quick-view',
    loadChildren: () =>
      import('./quick-view/quick-view.module').then(
        (m) => m.QuickViewPageModule
      ),
  },
  {
    path: 'wish-list',
    loadChildren: () =>
      import('./wish-list/wish-list.module').then((m) => m.WishListPageModule),
  },

  {
    path: 'order-details',
    loadChildren: () =>
      import('./order-details/order-details.module').then(
        (m) => m.OrderDetailsPageModule
      ),
  },
  {
    path: 'child-category',
    loadChildren: () =>
      import('./pages/child-category/child-category.module').then(
        (m) => m.ChildCategoryPageModule
      ),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationPageModule
      ),
  },

  {
    path: 'sub-chiled-category',
    loadChildren: () =>
      import('./sub-chiled-category/sub-chiled-category.module').then(
        (m) => m.SubChiledCategoryPageModule
      ),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./subscription/subscription.module').then(
        (m) => m.SubscriptionPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path: 'page/:id',
    loadChildren: () =>
      import('./pages/folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'enquiry-form',
    loadChildren: () =>
      import('./pages/enquiry-form/enquiry-form.module').then(
        (m) => m.EnquiryFormPageModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'all-brands',
    loadChildren: () =>
      import('./pages/all-brands/all-brands.module').then(
        (m) => m.AllBrandsPageModule
      ),
  },
  {
    path: 'packeges',
    loadChildren: () =>
      import('./pages/packeges/packeges.module').then(
        (m) => m.PackegesPageModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'help-support',
    loadChildren: () =>
      import('./pages/help-support/help-support.module').then(
        (m) => m.HelpSupportPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
