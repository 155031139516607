import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Subject, throwError, Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  cartParam,
  removeCartPram,
  sendVerificationOtp,
  Slider,
  userloginverify,
  verificationOtp,
  imageparam,
  pages_param,
} from './Modals';
import { UtilityService } from './utility.service';
import { ConnectService } from './connectservice.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  cmpanyLogo = 'assets/imgs/BrandLibrary-Logo2.png';
  bookPlaceholder = 'assets/icon/logo_placeholder.png';
  upload_placeholder = 'assets/icon/upload_placeholder.png';
  baseUrl: any = 'https://api.brandslibrary.in/api/';
  image_url: any = 'https://api.brandslibrary.in/';
  social_icon = {
    call: 'assets/icon/call.png',
    facebook: 'assets/icon/fb.png',
    instagram: 'assets/icon/insta.png',
    youtube: 'assets/icon/youtube.png',
    whatsapp: 'assets/icon/whatsapp.png',
    glob: 'assets/icon/glob.png',
  };
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  get_pages = this.baseUrl + 'pages';

  enquiry_form = this.baseUrl + 'enquiryform';
  get_wallet_amount = this.baseUrl + 'getWalletAmount';
  serchItem = this.baseUrl + 'searchItem';
  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private connectService: ConnectService
  ) {}

  // cartParam: cartParam = { 'user_token': this.utilityService.userToken.value, 'user_id': this.utilityService.logIn.value || 0 }
  headers: any = new HttpHeaders({ 'Content-Type': 'application/json' });

  fetchDashboard() {
    return this.sendRequest('GET', 'dashboard');
  }

  fetchFooterSection() {
    return this.sendRequest('GET', 'footerSection');
  }

  fetchNotification() {
    return this.sendRequest('GET', 'notification');
  }
  saveFcmToken(data: any) {
    return this.sendRequest('POST', 'saveFcmToken', data);
  }

  getPages = (data: pages_param) => {
    return this.http
      .get<Slider>(this.get_pages + '/' + data.name, { observe: 'response' })
      .pipe(catchError(this.handleError));
  };

  getAllCategory() {
    return this.sendRequest('GET', 'getAllCategory');
  }

  getAllBrands(data: any) {
    return this.sendRequest('GET', 'getAllBrands', data);
  }
  getSubCategory(category_id: any) {
    return this.sendRequest('GET', 'getSubCategory', category_id);
  }
  getBrandByCategory(category_id: any) {
    return this.sendRequest('GET', 'getBrandByCategory', category_id);
  }
  getBrandDetail(data: any) {
    return this.sendRequest('GET', 'getBrandDetail', data);
  }
  logout() {
    return this.sendRequest('POST', 'logout');
  }

  getUserToken() {
    return this.sendRequest('GET', 'getUserToken');
  }
  userRegistration = (data: any) => {
    return this.sendRequest('POST', 'user_registration', data);
  };

  addtoCart(item: cartParam) {
    return this.sendRequest('POST', 'addToCart', item);
  }

  getCartItems(item: cartParam) {
    return this.sendRequest('GET', 'getCartItems', item);
  }

  getCartCount(data: any) {
    return this.sendRequest('POST', 'getCartCount', data);
  }
  checkOut() {
    return this.sendRequest('GET', 'checkOut');
  }
  getOderCapcha() {
    return this.sendRequest('GET', 'getOderCapcha');
  }

  getPackageSlider() {
    return this.sendRequest('GET', 'packageSlider');
  }

  get_qrcode() {
    return this.sendRequest('GET', 'qrcode');
  }

  orderPlace(data: any) {
    return this.sendRequest('POST', 'orderPlace', data);
  }

  verifyCoupon(data: any) {
    return this.sendRequest('POST', 'appycoupon', data);
  }

  removeFromCart(item: removeCartPram) {
    return this.sendRequest('POST', 'removeFromCart', item);
  }

  sendverificationotp = (item: sendVerificationOtp) => {
    return this.sendRequest('POST', 'send-verification-otp', item);
  };

  userLoginVerify = (item: userloginverify) => {
    return this.sendRequest('POST', 'user-login-verify', item);
  };

  confirmVerificationOtp = (data: verificationOtp) => {
    return this.sendRequest('POST', 'confirm-verification-otp', data);
  };

  userEditProfile = (data: any) => {
    return this.sendRequest('POST', 'user_edit_profile', data);
  };

  getOrder = () => {
    return this.sendRequest('GET', 'orderDetails');
  };

  changeProfileImage = (data: any) => {
    return this.sendRequest('POST', 'change-profile-image', data);
  };

  enquiryform = (enquiryForm: any) => {
    return this.postData(this.enquiry_form, enquiryForm);
  };

  getWalletAmount = (data: any) => {
    return this.sendRequest('GET', 'getWalletAmount', data);
  };
  getCunsultOptions = () => {
    return this.sendRequest('GET', 'getCunsultOptions');
  };

  getTransaction = (data: any) => {
    return this.sendRequest('GET', 'getTransaction', data);
  };

  searchItem = (data: any) => {
    return this.http
      .get<any>(this.serchItem + '?search=' + data, { observe: 'response' })
      .pipe(catchError(this.handleError));
  };

  sendRequest = (type: string = 'POST', url: string, option?: any) =>
    this.connectService.getConnect(type, url, option);

  postData(url: any, options: any): Observable<any> {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
    return this.http.post(url, options, headers).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(async (error: HttpErrorResponse) => {
        this.cancel_all_connection();
        await this.loadingCtrl
          .getTop()
          .then(async (v) => (v ? await this.loadingCtrl.dismiss() : null));
        return throwError(error.name || 'server error');
      })
    );
  }

  // post method
  get_data(url: any, options: any): Observable<any> {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
    return this.http
      .post(this.baseUrl + url, JSON.stringify(options), headers)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(async (error: HttpErrorResponse) => {
          this.cancel_all_connection();
          await this.loadingCtrl
            .getTop()
            .then(async (v) => (v ? await this.loadingCtrl.dismiss() : null));
          return throwError(error.name || 'server error');
        })
      );
  }
  // get method
  get_data_final(url: any): Observable<any> {
    const header = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set( 'Accept', 'application/json',),
    };
    return this.http.get(this.baseUrl + url, header).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(async (error: HttpErrorResponse) => {
        this.cancel_all_connection();
        await this.loadingCtrl
          .getTop()
          .then(async (v) => (v ? await this.loadingCtrl.dismiss() : null));
        return throwError(error.name || 'server error');
      })
    );
  }

  public async cancel_all_connection(): Promise<void> {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    await this.loadingCtrl
      .getTop()
      .then(async (v) => (v ? await this.loadingCtrl.dismiss() : null));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
